<template>
    <div v-if="visible">

        <v-dialog 
            v-model="visible" 
            transition="dialog-top-transition" 
            persistent 
            max-width="1000"
        >
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t("Исполнение") }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text v-if="loading">
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        {{ $t("Загрузка...") }}
                    </v-col>
                    <v-col cols="12">
                        <v-progress-linear
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-col>
                </v-card-text>

                <v-card-text v-else-if="executeCard" class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            <!--#region Поступило_ходатайство_о_прекращении-->
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Поступило_ходатайство_о_прекращении") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-switch
                                        :input-value="executeCard.HasClosePetition"
                                        @change="$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', { property: 'HasClosePetition', value: $event })"
                                        inset
                                        hide-details
                                        class="cust-switch"
                                    />    
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                            <!--#region Решение_по_обращению-->
                            <v-row v-if="!executeCard.HasClosePetition" no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Решение_по_обращению") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-select
                                        :value="executeCard.Decision"
                                        @input="$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', { property: 'Decision', value: $event })"
                                        :items="decisions"
                                        :item-text="item => $refLocale(item, $i18n.locale)"
                                        return-object
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                    >
                                        <template v-slot:selection="data">
                                            {{data.item.Code}} - {{data.item.Value}}
                                        </template>

                                        <template v-slot:item="data">
                                            {{data.item.Code}} - {{data.item.Value}}
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                            <!--#region Суть_ответа_заявителю-->
                            <v-row v-if="!executeCard.HasClosePetition && executeCard.Decision && !redirectCodes.includes(executeCard.Decision.Code) && !sendRequestCodes.includes(executeCard.Decision.Code)" no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Суть_ответа_заявителю") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-textarea
                                        :value="executeCard.AnswerSummary"
                                        @input="debouncedUpdate({ property: 'AnswerSummary', value: $event })"
                                        rows="3"
                                        no-resize
                                        hide-details
                                        required
                                        outlined
                                        dense
                                    >
                                    </v-textarea>                                  
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                            <!--#region Исходящий_номер-->
                            <v-row v-if="!executeCard.HasClosePetition && executeCard.Decision && !notNeedOutNumber.includes(executeCard.Decision.Code)" no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Исходящий_номер") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        :value="executeCard.StOutNumber"
                                        @input="debouncedUpdate({ property: 'StOutNumber', value: $event })"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                            <!--#region Направлено_в_уполномоченный_гос-->
                            <v-row v-if="!executeCard.HasClosePetition && executeCard.Decision && redirectCodes.includes(executeCard.Decision.Code)" no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Направлено_в_уполномоченный_гос._орган") }}</label>
                                </v-col>
                                 <v-col cols="12" sm="12" md="9">
                                    <div class="icon-click-row-group icrg-align-center">

                                        <div
                                            @click="selectSendedUGO"
                                        >
                                            <v-icon small>
                                                fas fa-edit
                                            </v-icon>
                                        </div>

                                        <div class="onlyReadData">
                                            <v-enterprise-chip
                                                v-if="executeCard.SendedUGOGuid && executeCard.SendedUGOGuid != guidEmpty"
                                                :name="getEnterpriseName(executeCard.SendedUGOGuid)"
                                            />
                                        </div>

                                    </div>
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                            <!--#region Направлен_запрос_в_другой_орган-->
                            <v-row v-if="!executeCard.HasClosePetition && executeCard.Decision && sendRequestCodes.includes(executeCard.Decision.Code)" no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Направлен_запрос_в_другой_орган") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="icon-click-row-group icrg-align-center">

                                        <div
                                            @click="selectSendRequest"
                                        >
                                            <v-icon small>
                                                fas fa-edit
                                            </v-icon>
                                        </div>

                                        <div class="onlyReadData">
                                            <v-enterprise-chip
                                                v-if="executeCard.SendRequestGuid && executeCard.SendRequestGuid != guidEmpty"
                                                :name="getEnterpriseName(executeCard.SendRequestGuid)"
                                            />
                                        </div>

                                    </div>
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                            <!--#region Дата_направления_запроса-->
                            <v-row v-if="!executeCard.HasClosePetition && executeCard.Decision && sendRequestCodes.includes(executeCard.Decision.Code)" no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Дата_направления_запроса") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-menu 
                                        v-model="sendRequestDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="executeCard.SendRequestDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                class="datepick-input"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="sendRequestDate"
                                            @input="sendRequestDateMenu = false"
                                            color="teal"
                                            :first-day-of-week="1"
                                            :max="$moment().format('YYYY-MM-DD')"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                            <!--#region Дата_направления_ответа_либо_перенаправления/Дата_поступления_хадатайства-->
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ executeCard.HasClosePetition ? $t("Дата_поступления_ходатайства") : $t("Дата_направления_ответа_либо_перенаправления") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <v-menu 
                                        v-model="sendDateMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="executeCard.SendDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                class="datepick-input"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker
                                            v-model="sendDate"
                                            @input="sendDateMenu = false"
                                            color="teal"
                                            :first-day-of-week="1"
                                            :min="$moment(executeCard.StRegDate).format('YYYY-MM-DD')"
                                            :max="$moment().format('YYYY-MM-DD')"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                            <!--#region Вложения_для_перенаправления-->
                            <v-row v-if="!executeCard.HasClosePetition && executeCard.Decision && redirectCodes.includes(executeCard.Decision.Code)" no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Вложения_для_перенаправления") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="icon-click-row-group">

                                        <div @click="$refs.atachmentsRef.click()">
                                            <input
                                                type="file"
                                                ref="atachmentsRef"
                                                @change="onFilesPicked"
                                                style="display: none"
                                                multiple
                                            />
                                            <v-icon small>
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        
                                        <div class="cloud-file-list-box">
                                            <div class="cloud-items-container">

                                                <div v-if="!attachments || attachments.length == 0" class="cfl-placeholder">{{ $t('Нет_файлов') }}</div>

                                                <v-row 
                                                    v-else
                                                    dense
                                                >
                                                    <v-file-item
                                                        v-for="attachment in attachments" :key="attachment.name"
                                                        :name="attachment.name"
                                                        :size="attachment.size"
                                                        col-width="4"
                                                        :hide-load="true"
                                                        @on-delete-attachment="onDeleteAttachment(attachment)"
                                                    />
                                            </v-row>

                                            </div>
                                        </div>                
                                        

                                    </div>
                                </v-col>
                            </v-row>
                            <!--#endregion-->

                        </v-card-text>
                        
                    </v-card>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="cyan" 
                        text
                        depressed
                        @click="ok"
                        v-if="isValid"
                    >
                        {{$t("Добавить")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import sys from '@/services/system';

export default {
    name: "EUOLAddExecutionDlg",
    data: () => ({
        sendRequestDateMenu: false,
        sendDateMenu: false,
        contractors: []
    }),
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('dialogs/euolAddExecution',
        {
            visible: 'isVisible',
            loading: 'isLoading',
            decisions: 'getDecisions',
            redirectCodes: 'getRedirectCodes',
            notNeedOutNumber: 'getNotNeedOutNumber',
            sendRequestCodes: 'getSendRequestCodes',
            noAnswerCodes: 'getNoAnswerCodes',
            attachments: 'getAttachments',
            executeCard: 'getExecuteCard',
        }),
        ...mapGetters('attachments',
        {
            MaxFilesSize: 'getMaxFilesSize'
        }),
        sendRequestDate: {
            get: function() {
                if (this.executeCard.SendRequestDate)
                    return this.$moment(this.executeCard.SendRequestDate).format('YYYY-MM-DD');
                
                return this.executeCard.SendRequestDate;
            },
            set: function(newValue) {
                this.$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', { property: 'SendRequestDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        sendDate: {
            get: function() {
                if (this.executeCard.SendDate)
                    return this.$moment(this.executeCard.SendDate).format('YYYY-MM-DD');
                
                return this.executeCard.SendDate;
            },
            set: function(newValue) {
                this.$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', { property: 'SendDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        guidEmpty() {
            return '00000000-0000-0000-0000-000000000000';
        },
        isValid() {
            if (!this.executeCard)
                return false;

            if (this.executeCard.HasClosePetition)
                return this.executeCard.SendDate;

            if (this.executeCard.Decision) {

                if (this.redirectCodes.includes(this.executeCard.Decision.Code))
                    return this.executeCard.StOutNumber && (this.executeCard.SendedUGOGuid && this.executeCard.SendedUGOGuid != this.guidEmpty) && this.executeCard.SendDate;
                
                else if (this.sendRequestCodes.includes(this.executeCard.Decision.Code))
                    return this.executeCard.SendDate && this.executeCard.SendRequestDate && this.executeCard.StOutNumber && (this.executeCard.SendRequestGuid && this.executeCard.SendRequestGuid != this.guidEmpty)

                else {

                    if (this.notNeedOutNumber.includes(this.executeCard.Decision.Code))
                        return this.executeCard.SendDate && this.executeCard.AnswerSummary;
                    
                    return this.executeCard.SendDate && this.executeCard.StOutNumber && this.executeCard.AnswerSummary;
                }

                
            }

            return false;
        }
    },
    methods: {
        ...mapActions('dialogs/euolAddExecution', ['ok', 'cancel', 'selectSendedUGO', 'selectSendRequest']),
        ...mapActions('attachments', ['validateFile']),
        async onFilesPicked(e) {
            var attachments = Array.from(e.target.files);
            let passAttachments = [];
            let skipAttchments = [];
            let totalSize = 0;

            for (let attachment of attachments) {
                let checkResult = await this.validateFile(attachment);

                if (!checkResult.success) {
                    skipAttchments.push({ message: checkResult.message, attachment });
                    continue;
                }

                if (passAttachments.length == 5) {
                    skipAttchments.push({ message: this.$t('Превышен_лимит_количества_вложений'), attachment });
                    continue;
                }

                if (totalSize + attachment.size > this.MaxFilesSize) {
                    skipAttchments.push({ message: this.$t('Превышен_лимит_размера_вложений'), attachment });
                    continue;
                }

                totalSize += attachment.size;
                passAttachments.push(attachment);
            }

            skipAttchments.forEach(obj => {
                this.$notify.alert(this.$t('Ошибка_загрузки_файла:_filename_with_reason.message', { filename: obj.attachment.name, reason: obj.message }));
            });

            this.$store.commit('dialogs/euolAddExecution/SET_ATTACHMENTS', passAttachments);
            this.$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', { property: 'HasAttachments', value: passAttachments.length > 0 });

            e.target.value = '';
        },
        onDeleteAttachment(attachment) {
            let index = this.attachments.indexOf(attachment);
            let attachments = Array.from(this.attachments);
            attachments.splice(index, 1);
            this.$store.commit('dialogs/euolAddExecution/SET_ATTACHMENTS', attachments);
            this.$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', { property: 'HasAttachments', value: attachments.length > 0 });

        },
        debouncedUpdate: _.debounce(function (object) {
            this.$store.commit('dialogs/euolAddExecution/UPDATE_EXECUTE_CARD', object)
        }, 250),
        getEnterpriseName(enterpriseId) {
            let contractor = this.contractors.find(x => x[0] === enterpriseId);
            return contractor ? contractor[1] : "";
        }
    },
    async created() {
        this.contractors = await this.$store.dispatch('references/getContractors');
    }
};
</script>